import React from "react"

const NoDisponible = () => {
  return (
    <div>
      <h2>Por el momento playa naranjo se encuentra cerrado</h2>
    </div>
  )
}

export default NoDisponible
